
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc, Included, ResourceIdentifierObject, ResourceObject } from '@/models/jsonapi'
  import { Lead } from "@/models/leads";
  import { Leadsource } from '@/models/leadsources'
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher } from "@/utils/apiDataHelper"
  import { sortElements, sortData } from "@/utils/tableSort"
  import { fullName } from '@/utils/dataExtractors'
  import { sellerLeadIndexColumns } from "@/view_scripts/table_columns/lead_columns"
  import { newLeadSchema, newLeadUISchema } from "@/view_scripts/form_schemas/lead"
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"

  export default defineComponent({
    components: { SchemaForm },
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed(() => root.$store.state.leads.pagination.totalItems)
      const showLoading = ref(false)
      const leadEditModal = ref()
      const leadsources = computed(() => root.$store.state.leadsources.data)
      const columns = ref(sellerLeadIndexColumns)
      const lastLeadsourceId = ref<string>()
      const newLeadForm = ref<ResourceObject<string, Lead>>({
        type: 'leads',
        attributes: { cost: 0, price: 30 }
      })
      const uiSchema = computed(() => {
        if (leadsources.value && leadsources.value.length > 0) {
          const schema = newLeadUISchema(leadsources.value) as any
          delete schema.properties.attributes.properties.cost
          delete schema.properties.attributes.properties.price
          return schema
        }
      })

      const getItems = async(ctx: BvTableCtxObject) => {
        const { sortKey, sortColumn, sortFunction } = sortElements(ctx, columns.value)
        const queryParams = {
          ...(ctx.filter as any),
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'sort': sortFunction ? undefined : `${ctx.sortDesc ? '-' : ''}${ctx.sortBy?.replace('attributes.', '') ?? ''}`,
          'include':'address,proto_leadsource,households',
        }
        await root.$store.dispatch('leads/load', queryParams)
        if (sortFunction) { sortData(ctx, root.$store.state.leads.data, sortFunction) }
        return root.$store.state.leads.data
      }

      const filterRules = [
        {
          id: "address.first_name",
          label: "Vorname",
          type: "text",
          operators: ['is', 'is not']
        },
        {
          id: "address.last_name",
          label: "Nachname",
          type: "text",
          operators: ['is', 'is not']
        },
        {
          id: "createdAt",
          label: "Erstellt am",
          type: "date",
          operators: ['is', 'is not']
        },
        {
          id: "proto_leadsource.name",
          label: "Leadquelle",
          type: "text",
          operators: ['is', 'is not']
        },
        {
          id: "archived",
          label: "Archived",
          type: "boolean"
        },
        {
          id: "reclaimed",
          label: "Reclaimed",
          type: "boolean"
        }
      ]

      const titleActions = ref([
        {
          title: 'New Lead',
          icon: 'plus',
          tooltip: 'New Lead',
          onClick: () => {
            showLoading.value = true
            getLeadsources().then(() => {
              showLoading.value = false
              leadEditModal.value.show()
            })
          }
        }
      ])

      const getLeadsources = async () => {
        const leadSourceQueryParams = { 'filter[_type]': 'Leadsource' }
        root.$store.dispatch('leadsources/load', leadSourceQueryParams)
      }

      const saveLead = async (bvModalEvent: any) => {
        bvModalEvent.preventDefault()
        await root.$store.dispatch('leads/create', newLeadForm.value)
        root.$nextTick(() => leadEditModal.value.hide())
      }

      return {
        showLoading,
        getItems,
        totalItems,
        columns,
        filterRules,
        titleActions,
        newLeadForm,
        leadEditModal,
        lastLeadsourceId,
        saveLead,
        newLeadSchema,
        uiSchema,
        leadsources
      }
    }
  })
