import { render, staticRenderFns } from "./Leads.vue?vue&type=template&id=05e2f91a&scoped=true&lang=pug"
import script from "./Leads.vue?vue&type=script&lang=ts"
export * from "./Leads.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05e2f91a",
  null
  
)

export default component.exports